@import "../../styles/variables";

.mainHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $headerHeight;
  margin-bottom: 0 auto;
  background-color: $headerBgColor;
  box-shadow: 0 0 4px;
  z-index: 1;
}

.logo {
  width: 7vh;
  height: 7vh;
  margin-left: 1vmax;
  box-shadow: 0 0 2px;
  border-radius: 50%;
  transition: all 0.2s linear;
}

.logo:hover {
  transform: rotate(1turn);
}

.links {
  display: flex;
  margin-right: 2vmax;
  list-style: none;
  li {
    margin-right: 2vmax;
  }
  a {
    color: black;
    text-decoration: none;
    transition: all 0.3s linear;
    padding: 0 0.3rem;
    border-radius: 2px;
    border-bottom: 2px solid transparent;
  }
  a:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .activeLink {
    text-shadow: 0 0 1px;
    border-bottom: 2px solid black;
  }
}

@media (max-width: 300px) {
  .logo {
    margin-left: 0.2vmax;
  }
}

@import "../../styles/variables";

.projectCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: 1vmax;
  background: rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 3px;
  h2 {
    text-align: center;
    margin-top: 1vmax;
    text-shadow: 0 0 1px orange;
  }
  p {
    padding: 1vmax 2vmax;
    text-indent: 2vmax;
  }
  img {
    width: 95%;
    display: block;
    margin: 1.5vmax 0;
    box-shadow: 0 0 3px #e6ffab;
  }
}

.links {
  display: flex;
  margin: 0.3vmax;
  a {
    display: block;
    text-decoration: none;
    padding: 0.8vmax;
    color: black;
    margin: 0 1vmax;
    background-color: $headerBgColor;
    box-shadow: 0 0 2px;
    border-radius: 3px;
    transition: all 0.2s linear;
  }
  a:hover {
    box-shadow: 0 0 8px;
  }
  a:active {
    cursor: grab;
    box-shadow: 0 0 4px;
  }
}

@media (max-width: 550px) {
  .projectCard {
    width: 92%;
    margin-bottom: 3vh;
    h2 {
      font-size: 1.2rem;
    }
  }
}

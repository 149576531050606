@import "../../styles/variables";

.landing {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  margin: 3vh auto;
  width: 75%;
  min-height: 74vh;
  padding: 2vmax;
  box-shadow: 0 0 3px;
  background-color: rgba(134, 134, 134, 0.3);
  h1 {
    text-align: center;
  }
  h2,
  h3 {
    text-align: center;
    padding: 1vmax;
  }
  mark,
  i {
    background-color: transparent;
    text-shadow: 0 0 1px orangered;
  }
  h1 {
    text-shadow: 0 0 1px orangered;
  }
}

.pageSvg {
  width: 11vmax;
  height: auto;
  align-self: flex-end;
  margin-right: 3rem;
}

.bubbleSvg {
  width: 7vmax;
  height: auto;
  margin-left: 4rem;
}

@media (max-width: 900px) {
  .pageSvg {
    width: 18vmax;
    margin: 5vh 4vmax;
  }
  .bubbleSvg {
    width: 12vmax;
    margin: 5vh 4vmax;
  }
}

@media (max-width: 550px) {
  .landing {
    padding: 0.3vmax;
    width: 90%;
    h1,
    h2,
    h2 {
      padding: 0.3vmax;
    }
  }
}

@import "../../styles/variables";

.projectsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    padding: 1vmax;
    text-align: center;
    color: black;
    margin: 1vmax;
  }
}

@media (max-width: 550px) {
  .projectsContainer {
    h1 {
      font-size: 1.3rem;
    }
  }
}

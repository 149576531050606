@import "../../styles/variables";

@import "../../styles/variables";

.mainFooter {
  width: 100%;
  height: $headerHeight;
  margin-top: auto;
  background-color: $headerBgColor;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 4px;
  z-index: 1;
}

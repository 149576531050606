.aboutPage {
  margin: 2%;
  h1 {
    padding-bottom: 1vmax;
    margin-bottom: 2vmax;
    text-align: center;
    text-shadow: 0 0 1px orange;
    border-bottom: 1px solid white;
    animation: mainHeading 1s ease-in-out 1s;
  }
  h3 {
    text-shadow: 0 0 1px orangered;
  }
  ul {
    list-style: none;
    display: flex;
    padding: 0;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  li {
    margin-right: 3vmax;
    display: flex;
    align-items: center;
  }
  p {
    font-weight: bold;
    text-shadow: 0 0 2px;
    font-size: 1.1rem;
  }
  img {
    width: 30px;
    display: inline-block;
    align-self: center;
    background-color: white;
    border-radius: 3px;
  }
  img:hover {
    box-shadow: 0 0 4px;
  }
}

p.describeMe {
  margin-bottom: 1vmax;
  padding-bottom: 2vmax;
  text-shadow: 0 0 1px orange;
  border-bottom: 1px solid white;
  font-weight: normal;
  text-indent: 5%;
}

.skills {
  h3 {
    margin-bottom: 1vmax;
  }
  margin-bottom: 1vmax;
  padding-bottom: 2vmax;
  border-bottom: 1px solid white;
}

.contacts {
  margin-top: 2vmax;
  display: flex;
  align-content: center;
  justify-content: center;
  a {
    display: flex;
    text-decoration: none;
    color: black;
    margin: 0 7vmax;
    box-shadow: 0 0 8px;
    transition: all 0.2s ease;
  }
  a:hover {
    cursor: pointer;
    box-shadow: 0 0 20px;
  }
}

.email {
  animation: fly 5s linear infinite;
}

.linked {
  animation: fly 5.8s linear infinite;
}

.tweet {
  animation: fly 5.3s linear infinite alternate;
}

.git {
  animation: fly 5.2s linear infinite alternate-reverse;
}

@keyframes mainHeading {
  0% {
    letter-spacing: 0rem;
    text-shadow: 0 0 1px orange;
  }
  50% {
    letter-spacing: 0.08rem;
    text-shadow: 0 0 20px black;
  }
  100% {
    letter-spacing: 0rem;
    text-shadow: 0 0 1px orange;
  }
}

@keyframes fly {
  0% {
    transform: translateX(0px) translateY(0px);
  }
  25% {
    transform: translateX(2px) translateY(2px);
  }
  50% {
    transform: translateX(2px) translateY(0px);
  }
  75% {
    transform: translateX(0px) translateY(2px);
  }
  100% {
    transform: translateX(0px) translateY(0px);
  }
}

@media (max-width: 550px) {
  .aboutPage {
    h1 {
      font-size: 1.5rem;
    }
    h2 {
      font-size: 1.3rem;
    }
  }
  .contacts {
    a {
      margin: 2vmax 7vmax;
    }
  }
}
